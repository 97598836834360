import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import NotFoundPageComponent from "../components/page404/page404.component"

const NotFoundPage = () => (
  <Layout>
    <NotFoundPageComponent/>
  </Layout>
)

export default NotFoundPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
